import React, { useRef } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { gsap } from 'gsap/all';


export default function ButtonSubMenu ( { title, options } ) {
    
    const containerRef = useRef(null);
    const btnRef = useRef(null);
    const subMenuRef = useRef(null);
    let isMenuOpen = false;
    let buttonActive = false;
    const location = useLocation();

    const toggleSubMenu = (toggleOpen) => {
        if ( toggleOpen === isMenuOpen ) {
            return;
        }
        else if (typeof toggleOpen != 'boolean') {
            toggleOpen = !isMenuOpen;
        }
        
        isMenuOpen = toggleOpen;
        gsap.killTweensOf(subMenuRef.current);

        if ( isMenuOpen ) {
            gsap.fromTo(subMenuRef.current, 
                { height: '50%', opacity: 0, display: 'block' }, 
                { duration: 0.3, height: 'auto', opacity: 1 } );
        }
        else {
            gsap.to(subMenuRef.current, 
                { duration: 0.3, height: '50%', opacity: 0, display: 'none' } );
        }
    }

    const optionsList = [];
    let option;
    // Not yet in use
    // let lineGallery;

    for (let i = 0; i < options.length; i++) { 
        option = options[i];

        const match = matchPath({path: option.path}, location.pathname) !== null;
        const matchChild = matchPath({path: option.path + '/:child'}, location.pathname) !== null;
        buttonActive = buttonActive || match || matchChild;

        optionsList.push(
            <li key={i}>
                <NavLink
                    className={ ({ isActive }) => ( isActive ? ' header--sub-link__selected' : 'header--sub-link') }
                    to={option.path}
                    onClick={ toggleSubMenu }>
                    { option.title }
                </NavLink>
            </li>
        );
    }

    return (
        <>
            <div className='header--sub-menu-container'
                ref={ containerRef }
                onMouseLeave={ () => setTimeout(toggleSubMenu(false), 0) }>
                <button
                    className={ ( buttonActive ? ' header--link__selected' : 'header--link' ) }
                    ref={ btnRef }
                    onMouseEnter={ () => setTimeout(toggleSubMenu(true), 0) }
                    onClick={ toggleSubMenu }>
                    { title }
                </button>
                <div className='header--sub-menu' ref={ subMenuRef }>
                    <ul>
                        { optionsList }
                    </ul>
                </div> 
            </div> 
        </>       
    );

}