
import React, { useRef } from 'react';
import OnImagesLoaded from 'react-on-images-loaded';
import { utilAnimate } from '../util/utilAnimate';


export default function LoadContainer ( { children } ) {

    const loaderRef = useRef(null);
    const containerRef = useRef(null);
    const onImagesLoaded = () => {
        utilAnimate.fadeOut(loaderRef.current);
        utilAnimate.fadeIn(containerRef.current);
    }

    return (
        <>
            <div className='shared--loading'  ref={ loaderRef }></div>
            <div className='shared--load-container' ref={ containerRef }>
                <OnImagesLoaded onLoaded={ onImagesLoaded } >
                    { children }
                </OnImagesLoaded>
            </div>
        </>
    );

}