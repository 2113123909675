import React from 'react';
import { connect } from 'react-redux';
import ConnectButtonLightingHotspot from '../../components/hotspots/ConnectButtonLightingHotspot';
import { selectArea, deselectArea, selectAll, deselectAll, selectIndependantSection, deselectIndependantSection } from '.';

function ViewLightingUI ( { data, residences, selectIndependantSection, deselectIndependantSection, selectArea, deselectArea, selectAll, deselectAll } ) {
    const btnList = [];
    
    for (let i = 0; i < data.length; i++) { 
        const lightData = data[i];
        // let button;
        switch (lightData.behaviour) {
            case 'all':
                btnList.push(
                    <ConnectButtonLightingHotspot 
                        key={i} 
                        data={ lightData } 
                        isOn={ residences.isAllActive }
                        onClickOn={ () => selectAll() } 
                        onClickOff={ () => deselectAll() } />
                );
                break;
            case 'all-on':
                btnList.push(
                    <ConnectButtonLightingHotspot 
                        key={i} 
                        data={ lightData } 
                        isOn={ residences.isAllActive }
                        onClickOn={ () => selectAll() } 
                        onClickOff={ () => {} } />
                );
                break;
            case 'all-off':
                btnList.push(
                    <ConnectButtonLightingHotspot 
                        key={i} 
                        data={ lightData } 
                        isOn={ !residences.isAllActive }
                        onClickOn={ () => deselectAll() } 
                        onClickOff={ () => deselectAll() } />
                );
                break;
            case 'area':
                btnList.push(
                    <ConnectButtonLightingHotspot 
                        key={i} 
                        data={ lightData } 
                        isOn={ residences.isAreaActive && residences.area === lightData.title && !residences.areLevelsActive }
                        onClickOn={ () => selectArea(lightData.title) } 
                        onClickOff={ () => deselectArea(lightData.title) } />
                );
                break;
            case 'independant-section':
                btnList.push(
                    <ConnectButtonLightingHotspot 
                        key={i} 
                        data={ lightData } 
                        isOn={ residences.independantSections && residences.independantSections.indexOf(lightData.title) > -1 }
                        onClickOn={ () => selectIndependantSection(lightData.title) } 
                        onClickOff={ () => deselectIndependantSection(lightData.title) } />
                );
                break;
            default:
                break;
        }
    }

    return btnList;
}


const mapStateToProps = (state) => ({
    residences: state.residences,
});

const mapDispatchToProps = (dispatch) => ({
    selectIndependantSection: (section) => dispatch(selectIndependantSection(section)),
    deselectIndependantSection: (section) => dispatch(deselectIndependantSection(section)),
    selectArea: (area) => dispatch(selectArea(area)),
    deselectArea: () => dispatch(deselectArea()),
    selectAll: () => dispatch(selectAll()),
    deselectAll: () => dispatch(deselectAll()),
});


export default connect(mapStateToProps, mapDispatchToProps)(ViewLightingUI);