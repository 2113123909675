import React, {useEffect} from 'react'
import { connect } from 'react-redux';
import { selectItem } from '.';


function ButtonItem ( { item, isSelected, currItem, selectItem } ) {
  
    //replace spaces with - and remove grammar
    // const itemStripped = item.replace(/\s/g, '-').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase(); 
    // const iconSrc = './imgs/location-aerial-map/icon--' + itemStripped + '.png';

    useEffect(() => {
        if ( isSelected )
        {
            selectItem(item)
        }
    }, [item, isSelected, selectItem])
    
    return (
        <button 
            className={ item === currItem ? 'multipage--btn__active' : 'multipage--btn' } 
            onClick={ () => selectItem( item ) } 
            >
            { item }
        </button>
    );
}


const mapStateToProps = (state) => ({
  currItem: state.multipage.currItem
});

const mapDispatchToProps = (dispatch) => ({
  selectItem: (currItem) => dispatch(selectItem(currItem))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonItem);