import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { selectLevelGroup, closeLevelGroup, selectArea, deselectArea } from '.';
import { selectOverlay, selectOverlaySlider } from '../../components/overlay';
import { gsap } from 'gsap/all';


function ButtonResidencesHotspot ( { data, residences, selectLevelGroup, closeLevelGroup, selectArea, deselectArea, selectOverlay, selectOverlaySlider } ) {

    const btnRef = useRef(null);
    const subMenuRef = useRef(null);
    const isMenuOpen = residences.isAreaActive && residences.area === data.title;

    const getSubMenu = () => {
        const optionsList = [data.rotation];
        const menuKeys = ( data.menuGroups && Object.keys(data.menuGroups) ) || [];
        const menuGroups = data.menuGroups || {};

        for (let i = 0; i < menuKeys.length; i++) {
            const menuKey = menuKeys[i];
            const menuValue = menuGroups[menuKey];

            let clickFunction = () => {};

            switch(menuKey) {
                case 'LEVELS':
                    clickFunction = () => {
                        let isLevelGroupOpen = residences.isLevelGroupActive && ( data.title === residences.levelGroup );
                        isLevelGroupOpen ? closeLevelGroup() : selectLevelGroup(menuValue)
                    };
                    break;
                case 'FLY-THROUGH':
                    clickFunction = () => selectOverlay( { type: 'video', src: menuValue } );
                    break;
                case 'VIRTUAL TOUR':
                    clickFunction = () => selectOverlay( { type: 'vr', src: menuValue } );
                    break;
                case 'CGI':
                    const slides = menuValue.map( (img) => ( { src: img } ) );
                    clickFunction = () => selectOverlaySlider( { slideList: slides, options: {} } );
                    break;
                case 'FLOORPLANS':
                    clickFunction = () => selectOverlay( menuValue );
                    break;
                case 'PANORAMA':
                    clickFunction = () => selectOverlay( { type: 'vr', src: menuValue } );
                    break;
                default:
                    break;
            }

            optionsList.push(
                <li key={i}>
                    <button 
                        className='residences--hotspot-sub-menu-item'
                        onClick={ clickFunction } >
                            { menuKey }
                    </button>
                </li>
            );
        }
        return optionsList;
    }

    useEffect( () => {
        if (isMenuOpen) {
            btnRef.current.classList.add('residences--hotspot-btn__active');
            subMenuRef.current.style.display = 'block';
            gsap.fromTo(subMenuRef.current, { height: 0, opacity: 0 }, { duration: 0.3, height: 'auto', opacity: 1, overwrite: true } );
        }
        else
        {
            btnRef.current.classList.remove('residences--hotspot-btn__active');
            gsap.to(subMenuRef.current, { duration: 0.3, height: 0, opacity: 0, display: 'none', overwrite: true } );
        }
    }, [isMenuOpen] );

    return (
        <div className='residences--hotspot' style={ { left: data.left, top: data.top } }>
            <button 
                className='residences--hotspot-btn'
                ref={ btnRef }
                onMouseEnter={ () => selectArea(data.title) } 
                onClick={ () => ( isMenuOpen ?  deselectArea() : selectArea(data.title) ) }>
                    { data.title }
            </button>
            <div className='residences--hotspot-sub-menu' ref={ subMenuRef }>
                <ul>
                    { getSubMenu() }
                </ul>
            </div>
        </div>
    );

}


const mapStateToProps = (state) => ({
    residences: state.residences
});

const mapDispatchToProps = (dispatch) => ({
    selectLevelGroup: (levelGroup) => dispatch(selectLevelGroup(levelGroup)),
    closeLevelGroup: () => dispatch(closeLevelGroup()),
    selectArea: (area) => dispatch(selectArea(area)),
    deselectArea: () => dispatch(deselectArea()),
    selectOverlay: (data) => dispatch(selectOverlay(data)),
    selectOverlaySlider: (data) => dispatch(selectOverlaySlider(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonResidencesHotspot);