import React from 'react';
import { connect } from 'react-redux';
import { data } from '../../data/building';
import ConnectButtonResidencesHotspot from './ConnectButtonResidencesHotspot';
import ConnectViewResidencesLevelGroup from './ConnectViewResidencesLevelGroup';
import ViewPhotoHotspots from '../../components/hotspots/ViewPhotoHotspots';
import ConnectViewLightingUI from './ConnectViewLightingUI';

function ViewResidencesRotation ( { rotation, currRotation, residences } ) {

    const display = rotation === currRotation ? 'block' : 'none'; //only if it's the currently active rotation
    const stringRotation = rotation < 10 ? ('0' + rotation) : rotation.toString(); //those below 10 have a starting 0
    const fileRef = process.env.PUBLIC_URL + '/imgs/building/DEX008_3D Floorplans_0' + stringRotation + '.jpg';
    
    const getHotspots = () => {
        const btnList = [];
        let currData;
        if (data.rotationHotspots[rotation] !== undefined) { //for those with hotspots, so only the rotations that have stops
            const dataList = data.rotationHotspots[rotation]; 
            for (let i = 0; i < dataList.length; i++) { 
                currData = dataList[i];
                currData.menuGroups = data.menuGroups[currData.title];
                currData.levelGroups = data.levelGroups[currData.title];
                btnList.push(
                    <ConnectButtonResidencesHotspot key={ i } data={ currData } />
                );
            }
        }
        return btnList;
    }

    const getLevelGroups = () => {
        const btnList = [];
        let currData;
        //for those with hotspots, so only the rotations that have stops
        if (data.rotationLevelGroups[rotation] !== undefined) {
            const dataList = data.rotationLevelGroups[rotation]; 
            for (let i = 0; i < dataList.length; i++) { 
                currData = dataList[i];
                currData.levelGroups = data.levelGroups[currData.title];
                btnList.push(
                    <ConnectViewResidencesLevelGroup key={ i } data={ currData } />
                );
            }
        }
        return btnList;
    }
  
    const getPhotoHotspots = () => {
        //for those with hotspots, so only the rotations that have stops
        if (data.rotationPhotoHotspots && data.rotationPhotoHotspots[rotation] !== undefined) { 
            return <ViewPhotoHotspots data={ data.rotationPhotoHotspots[rotation] } />
        }
    }
    
    const getLightingHotspots = () => {
        //for those with hotspots, so only the rotations that have stops
        if (data.rotationLightingHotspots && data.rotationLightingHotspots[rotation] !== undefined) { 
            return <ConnectViewLightingUI data={ data.rotationLightingHotspots[rotation] } />
        }
    }

    const getOverlay = () => {
        const overlayData = data.rotationOverlays[rotation];
        const overlayImages = [];
        
        if ( !overlayData || Object.keys(overlayData).length === 0 ) { return }

        const overlayKeys = Object.keys(overlayData);

        const overlayArea = residences.isAreaActive && residences.area;
        let overlayArray = ( residences.areLevelsActive && residences.levels ) || ( overlayArea && [ overlayArea ] ) || [];
        overlayArray = residences.independantSections.concat(overlayArray);

        const activeOverlays = overlayArray.filter( (key) => overlayKeys.indexOf(key) > -1 );

        for ( let idx=0; idx<activeOverlays.length; idx++ )
        {
            const key = activeOverlays[idx];
            const overlay = overlayData[key];

            overlayImages.push(
                <img 
                    key={ idx }
                    className='residences--rotation-overlay' 
                    src={ process.env.PUBLIC_URL + overlay.src } 
                    alt={ key }
                    style={ { top: overlay.top, left: overlay.left } } />
            );
        }

        return ( overlayImages );
    }

    return (
        <div style={ { display: display } } >
            <img src={ fileRef } className='shared--img-fill' alt='Rotation View' style={ { display: display } } />
            { getOverlay() }
            <div className='features--hotspots-container'>
                { getHotspots() }
                { getPhotoHotspots() }
                { getLightingHotspots() }
                { getLevelGroups() }
                
            </div>
        </div>
    );
  
}


const mapStateToProps = (state) => ({
    currRotation: state.residences.currRotation,
    residences: state.residences,
});

const mapDispatchToProps = (dispatch) => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(ViewResidencesRotation);