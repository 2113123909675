import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import { dexus as page } from './dataTeam';
import './stylesTeam.scss';

export default function ViewTeamDexus () {
    
    const generateImage = () => {
        return (
            <div className='team--image-container'>
                <img className='team--image' src={ process.env.PUBLIC_URL + page.image } alt='' />
            </div>
        );
    }

    const pageContents = page.pageContents;

    return (
        <ViewSplitPage
            className='team--page split-page--square-left'
            pageLeft={ pageContents }
            pageRight={ generateImage() } />
    );
}