
import React from 'react';
import './stylesHighlights.scss';


export default function Viewhighlights () {

    const highlights = [];
    const dataHighlights = [
        {
            title: 'BEST SITE',
            statement: 'in Melbourne CBD',
        },
        {
            title: 'PRESTIGIOUS',
            statement: 'Collins Street location in Melbourne’s Eastern Core',
        },
        {
            title: 'PREMIUM',
            statement: 'grade office space across ~43,000sqm',
        },
        {
            title: 'TRULY RARE',
            statement: 'only the second development in the precinct in over 30 years',
        },
        {
            title: '6 STAR',
            statement: 'Green Star rating target',
        },
        {
            title: 'PARTNER',
            statement: 'with Dexus for exclusive access to our leading real asset platform',
        },
    ];

    for ( let idx=0; idx<dataHighlights.length; idx++ ) {
        const highlight = dataHighlights[idx];
        highlights.push(
            <div key={ idx } className='building-highlights--highlight'>
                <h2 className='title highlights--title'>{ highlight.title }</h2>
                <p className='statement highlights--statement'>{ highlight.statement }</p>
                <p className='statement highlights--statement2'>{ highlight.statement2 }</p>
            </div>
        );
    }
    
    return (
        <div className='highlights--transform-container building-highlights'>
            { highlights }
        </div>
    );
}