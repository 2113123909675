
import ViewHome from './home/ViewHome';
import ViewVision from './vision/ViewVision';
import ViewVirtualTour from './vision/ViewVirtualTour';
import ConnectViewResidences from './building/ConnectViewResidences';
import ViewGallery from './gallery/ViewGallery';

import Viewhighlights from './highlights/ViewHighlights';
import ViewArrival from './highlights/ViewArrival';
import ViewWorkspace from './highlights/ViewWorkspace';
import ViewTechnology from './highlights/ViewTechnology';
import ViewSustainability from './highlights/ViewSustainability';
import ViewWellness from './highlights/ViewWellness';
import ViewServices from './highlights/ViewServices';
import ViewPrecinct from './highlights/ViewPrecinct';
import ViewDexusPlace from './highlights/ViewDexusPlace';
import ViewProjectPillars from './highlights/ViewProjectPillars';
import ViewTourAll from './tour/ViewTourAll';
import ViewTeamDexus from './team/ViewTeamDexus';
import ViewTeamBatesSmart from './team/ViewTeamBatesSmart';
import ViewMultipage from '../components/multipage/ViewMultipage';
import dataLocation from '../data/locations';

export const dataRoutes = [
    {
        title: 'Vision',
        path: '/vision',
        element: <ViewVision/>
    },
    {
        title: 'Virtual Tour',
        path: '/virtual-tour',
        inMenu: false,
        element: <ViewVirtualTour/>
    },
    {
        title: 'Location',
        path: '/location',
        element: <ViewMultipage data={ dataLocation } />
    },
    {
        title: 'highlights',
        path: '/building-highlights',
        element: <Viewhighlights />,
                secondaryMenu: true,
                routes: [
                    
                    {
                        title: 'Arrival',
                        path: '/building-highlights/arrival',
                        element: <ViewArrival />,
                    },
                    {
                        title: 'Workspace',
                        path: '/building-highlights/workspace',
                        element: <ViewWorkspace />,
                    },
                    {
                        title: 'Technology',
                        path: '/building-highlights/technology',
                        element: <ViewTechnology />,
                    },
                    {
                        title: 'Sustainability',
                        path: '/building-highlights/sustainability',
                        element: <ViewSustainability />,
                    },
                    {
                        title: 'Wellness',
                        path: '/building-highlights/wellness',
                        element: <ViewWellness />,
                    },
                    /*{
                        title: 'Services',
                        path: '/building-highlights/sevices',
                        element: <ViewServices />,
                    },*/
                    {
                        title: 'Precinct',
                        path: '/building-highlights/precinct',
                        element: <ViewPrecinct />,
                    },
                    /*{
                        title: 'Dexus Place',
                        path: '/building-highlights/dexus-place',
                        element: <ViewDexusPlace />,
                    },*/
                ]
            
        
    },
    {
        title: 'Building',
        path: '/building',
        element: <ConnectViewResidences />
    },
    {
        title: 'Gallery',
        routes: [
            {
                title: 'CGIs',
                path: '/gallery-cgi',
                element: <ViewGallery/>
            },
            {
                title: 'VIEWS',
                path: '/panoramas',
                element: <ViewTourAll/>
            }
        ]
    },
    {
        title: 'Team',
        path: '/team-dexus',
        secondaryMenu: true,
        routes: [
            {
                title: 'Dexus',
                path: '/team-dexus',
                element: <ViewTeamDexus />
            },
            {
                title: 'Bates Smart',
                path: '/team-bates-smart',
                element: <ViewTeamBatesSmart />
            }
        ]
    },
    {
        title: 'Home',
        path: '/',
        element: <ViewHome />
    }
];