
import React from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import ButtonSubMenu from './ButtonSubMenu';
import { dataRoutes } from '../../routes/dataRoutes';
import './stylesMenu.scss';
import ConnectSecondaryMenu from './ConnectSecondaryMenu';

const findRoute = (routeList, currentLocation) => {
    for (let idx = 0; idx < routeList.length; idx++) {
        const currentRoute = routeList[idx];

        if ( matchPath({ path: '', ...currentRoute }, currentLocation) ) {
            return currentRoute;
        }

        if ( Array.isArray(currentRoute.routes) ) {
            let childRoute = findRoute(currentRoute.routes, currentLocation);

            if ( childRoute ) {
                childRoute.parents = childRoute.parents ? [...childRoute.parents, currentRoute] : [currentRoute];
                return childRoute;
            }
        }
    }
}

export default function ViewMenu () {

    const loc = useLocation();
    const location = loc.pathname;
    const route = findRoute(dataRoutes, location);
    const showSecondaryMenu = (route.secondaryMenu) || (route.parents && route.parents[0].secondaryMenu);
    const secondaryMenuRoutes = (route.routes) || (route.parents && route.parents[0].routes);

    const links = () => {
        const links = [];

        for (let idx = 0; idx < dataRoutes.length-1; idx++) { //skip home as it's used as the logo
            const currRoute = dataRoutes[idx];
            const parentOfActive = route.parents && ( route.parents.indexOf(currRoute) > -1 );
            
            //if there's no title the link is assumed to be accessed elsewhere, outside the primary menu
            if (currRoute.title && ( !currRoute.hasOwnProperty('inMenu') || currRoute.inMenu ) ) { 
                links.push(
                    <li className='header--nav__li' key={ idx }>
                        {
                        ( currRoute.routes === undefined || currRoute.secondaryMenu ) ? //whether it's a drop down or not
                        <NavLink 
                            className={ ({ isActive }) => ( isActive || parentOfActive ) ? 'header--link__selected' : 'header--link' }
                            to={ currRoute.path }>
                                { currRoute.title }
                        </NavLink> :
                        <ButtonSubMenu title={ currRoute.title } options={ currRoute.routes } />
                        }
                    </li>
                );
            }
        }

        return links;
    }

    const secondaryMenu = () => {
        if ( showSecondaryMenu )
        {
            return ( <ConnectSecondaryMenu routes={ secondaryMenuRoutes } /> );
        }
    }

    return (
        <header className='header'>
            <nav className='header--nav'>
                <ul>
                    <li className=''>
                        <NavLink className='header--link-logo' to='/'>
                            <img src={ process.env.PUBLIC_URL + '/imgs/interface/logo.png' } width='auto' height='auto' alt='Sixty Collins' />
                        </NavLink>
                    </li>
                    { links() }
                    <li className=''>
                        <span className='header--link-logo'>
                            <img src={ process.env.PUBLIC_URL + '/imgs/interface/logo-dexus.svg' } width='150' height='auto' alt='Dexus' />
                        </span>
                    </li>
                </ul>
                { secondaryMenu() }
            </nav>
        </header>
    );

}