const data = {
    filePrefix: '/imgs/location/location_',
    fileSufix: '_bkg.jpg',
    backgroundImage: '/imgs/location/train.jpg',
    pages: [
        { title: 'Train', default: true },
        { title: 'Tram' },
        { title: 'Dining' },
        { title: 'Wellness' },
        { title: 'Shopping' },
        { title: 'Entertainment' },
        { title: 'Culture' },
    ]
};

export default data;