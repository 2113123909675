import { apiFetch } from "./api";


var host = '192.168.1.100:3000';

(window).setHost = function(newHost){
    host = newHost;
}


async function turnOnLevel(id){
    try{
        id = twoDigitId(id);
        const response = await apiFetch(`http://${host}/group?number=level_${id}`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


async function turnOnArea(area){
    let areaStripped = area.replace(/-/g, '_').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase(); 
    try{
        const response = await apiFetch(`http://${host}/group?number=${areaStripped}`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


async function allOn(){
    try{
        const response = await apiFetch(`http://${host}/allOn`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


async function allOff(){
    try{
        const response = await apiFetch(`http://${host}/allOff`, {});
        return await response.text();
    }catch(e){
        return null;
    }
}


function twoDigitId(id){
    if(id.length !== 1 && id.length !== 2) throw("Invalid id: " + id);
    if(id.length === 1) return '0' + id;
    return id;
}


export default{
    turnOnLevel,
    turnOnArea,
    allOn,
    allOff,
}