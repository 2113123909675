
import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesHighlights.scss';

export default function ViewTechnology () {
    
    
    const image = <img className='image highlights--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/technology.jpg' } alt='' />;

    const page = (
        <div className='copy-container highlights--copy-container'>
            <h1 className='title highlights--title'>Smart Building Technology</h1>
            <p className='statement highlights--statement'>Access control system</p>
            <p className='statement highlights--statement'>Integrated visitor management</p>
            <p className='statement highlights--statement'>Space usage analytics</p>
            <p className='statement highlights--statement'>Smart lighting</p>
            <p className='statement highlights--statement'>Wifi &amp; internet services</p>
            <p className='statement highlights--statement'>Destination controlled lifts</p>
            <p className='statement highlights--statement'>5G distributed antenna systems</p>
        </div>
    );
    
    return (
        <ViewSplitPage 
            className='highlights--transform-container highlights--split-page'
            pageLeft={ page }
            pageRight={ image } />
    );
}