import produce from 'immer';


// Actions
const UPDATE_ROTATION = 'DISPLAY_APP/RESIDENCES/UPDATE_ROTATION';
const UPDATE_IS_ANIMATING = 'DISPLAY_APP/RESIDENCES/UPDATE_IS_ANIMATING';
const SELECT_AREA = 'DISPLAY_APP/RESIDENCES/SELECT_AREA';
const DESELECT_AREA = 'DISPLAY_APP/RESIDENCES/DESELECT_AREA';
const SELECT_LEVEL_GROUP = 'DISPLAY_APP/RESIDENCES/SELECT_LEVEL_GROUP';
const CLOSE_LEVEL_GROUP = 'DISPLAY_APP/RESIDENCES/CLOSE_LEVEL_GROUP';
const SELECT_LEVEL = 'DISPLAY_APP/RESIDENCES/SELECT_LEVEL';
const DESELECT_LEVEL = 'DISPLAY_APP/RESIDENCES/DESELECT_LEVEL';
const SELECT_INDEPENDANT_SECTION = 'DISPLAY_APP/RESIDENCES/SELECT_INDEPENDANT_SECTION';
const DESELECT_INDEPENDANT_SECTION = 'DISPLAY_APP/RESIDENCES/DESELECT_INDEPENDANT_SECTION';
const SELECT_ALL = 'DISPLAY_APP/RESIDENCES/SELECT_ALL';
const DESELECT_ALL = 'DISPLAY_APP/RESIDENCES/DESELECT_ALL';

const defaultState = { 
    currRotation: 0, 
    isAnimating: false,
    area: '',
    isAreaActive: false,
    levelGroup: '',
    isLevelGroupActive: false,
    levels: [],
    independantSections: [],
    areLevelsActive: false,
    isAllActive: false,
}


// Reducer
export default function reducer (state = defaultState, action = {}) {
    switch (action.type) {
        case UPDATE_ROTATION :
            return produce(state, draftState => {
                draftState.currRotation = action.currRotation;
            });
        case UPDATE_IS_ANIMATING :
            return produce(state, draftState => {
                draftState.isAnimating = action.isAnimating;
            });
        case SELECT_AREA :
            return produce(state, draftState => {
                draftState.isAreaActive = true;
                draftState.area = action.area;
            });
        case DESELECT_AREA :
            return produce(state, draftState => {
                draftState.isAreaActive = false;
                draftState.isLevelGroupActive = false;
            });
        case SELECT_LEVEL_GROUP :
            return produce(state, draftState => {
                draftState.isLevelGroupActive = true;
                draftState.levelGroup = action.levelGroup;
            });
        case CLOSE_LEVEL_GROUP :
            return produce(state, draftState => {
                draftState.isLevelGroupActive = false;
            });
        case SELECT_LEVEL :
            return produce(state, draftState => {
                draftState.areLevelsActive = true;
                draftState.levels = [ ...draftState.levels, action.level ] ;
            });
        case DESELECT_LEVEL :
            return produce(state, draftState => {
                const rmLevel = draftState.levels.filter( value => ( value !== action.level ) );
                draftState.levels = [...rmLevel];
                draftState.areLevelsActive = draftState.levels.length > 0;
            });
        case SELECT_INDEPENDANT_SECTION :
            return produce(state, draftState => {
                draftState.independantSections = [ ...draftState.independantSections, action.section ];
            });
        case DESELECT_INDEPENDANT_SECTION :
            return produce(state, draftState => {
                const rmIndependantSection = draftState.independantSections.filter( value => ( value !== action.section ) );
                draftState.independantSections = [...rmIndependantSection];
            });
        case SELECT_ALL :
            return produce(state, draftState => {
                draftState.isAllActive = true;
            });
        case DESELECT_ALL :
            return produce(state, draftState => {
                draftState.isAreaActive = false;
                draftState.isLevelGroupActive = false;
                draftState.areLevelsActive = false;
                draftState.levels = [];
                draftState.independantSections = []
                draftState.isAllActive = false;
            });
        default :
            return state;
    }
}


// Action Creators
export const updateRotation = (currRotation) => ( {
    type: UPDATE_ROTATION,
    currRotation
} );

export const updateIsAnimating = (isAnimating) => ( {
    type: UPDATE_IS_ANIMATING,
    isAnimating
} );

export const selectArea = (area) => {
    return ( {
    type: SELECT_AREA,
    area
} )};

export const deselectArea = () => ( {
    type: DESELECT_AREA
} );

export const selectLevelGroup = (levelGroup) => ( {
    type: SELECT_LEVEL_GROUP,
    levelGroup
} );

export const closeLevelGroup = () => ( {
    type: CLOSE_LEVEL_GROUP
} );

export const selectLevel = (level) => ( {
    type: SELECT_LEVEL,
    level
} );

export const deselectLevel = (level) => ( {
    type: DESELECT_LEVEL,
    level
} );

export const selectIndependantSection = (section) => ( {
    type: SELECT_INDEPENDANT_SECTION,
    section
} );

export const deselectIndependantSection = (section) => ( {
    type: DESELECT_INDEPENDANT_SECTION,
    section
} );

export const selectAll = () => ( {
    type: SELECT_ALL
} );

export const deselectAll = () => ( {
    type: DESELECT_ALL
} );