import produce from 'immer';


// Actions
const SELECT_ITEM = 'DISPLAY_APP/MULTIPAGE/SELECT_ITEM';


// Reducer
export default function reducer (state = { currItem: '' }, action = {}) {
    switch (action.type) {
        case SELECT_ITEM :
            return produce(state, draftState => {
                draftState.currItem = action.currItem;
            });       
        default :
            return state;
    }
}


// Action Creators
export const selectItem = (currItem) => ( {
    type: SELECT_ITEM,
    currItem
} );
