
export const lobby = {
    type: 'floorplan',
    options: {color: '#8a1438'},
    imgOverlay: '/imgs/floorplans/lobby--image-text.png',
    img: {
        ground: '/imgs/floorplans/floorplan_lobby_ground_2d.png',
        mezz: '/imgs/floorplans/floorplan_lobby_mezz_2d.png',
    },
    pageContents: [
        { type: 'title', text: 'A Tailored Arrival', },
        { type: 'statement', text: 'Three distinct entry points', },
        { type: 'statement', text: 'Two prime street front ages', },
        { type: 'statement', text: 'Quintessential Melbourne laneway', },
        { type: 'spacer', height: '50px', },
        { type: 'button', text: 'View Ground Plan', state: { level: 'ground' }, },
        { type: 'button', text: 'View Mezzanine Plan', state: { level: 'mezz' }, },
    ]
};

export const lobby3D = {
    type: 'floorplan',
    options: {color: '#8a1438'},
    className: 'floorplan-image-nudge',
    imgOverlay: {
        ground2d: '/imgs/floorplans/lobby--image-text.png',
        mezz2d: '/imgs/floorplans/lobby--image-text.png',
        ground3d: '',
        mezz3d: '',
    },
    img: {
        ground2d: '/imgs/floorplans/floorplan_lobby_ground_2d.png',
        mezz2d: '/imgs/floorplans/floorplan_lobby_mezz_2d.png',
        ground3d: '/imgs/floorplans/floorplan_lobby_ground_3d.png',
        mezz3d: '/imgs/floorplans/floorplan_lobby_mezz_3d.png',
    },
    pageContents: [
        { type: 'title', text: 'A Tailored Arrival', },
        { type: 'statement', text: 'Three distinct entry points', },
        { type: 'statement', text: 'Two prime street front ages', },
        { type: 'statement', text: 'Quintessential Melbourne laneway', },
        { type: 'spacer', height: '50px', },
        { type: 'button', text: 'View Ground Plan', state: { level: 'ground' }, },
        { type: 'button', text: 'View Mezzanine Plan', state: { level: 'mezz' }, },
        { type: 'spacer', height: '20px', },
        { type: 'button', text: 'View 2D', state: { version: '2d' }, },
        { type: 'button', text: 'View 3D', state: { version: '3d' }, },
    ]
};

export const highrise = {
    type: 'floorplan',
    options: {color: '#8a1438'},
    className: 'split-page--square-right floorplan-image-shift floorplan__smaller',
    img: {
        level2d: '/imgs/floorplans/floorplan_highrise_2d.png',
        level3d: '/imgs/floorplans/floorplan_highrise_3d.png',
    },
    pageContents: [
        { type: 'title', text: 'Typical Floors <br/> High-Rise', },
        { type: 'statement', text: 'Sight lines to external views and abundant natural light', },
        { type: 'statement', text: '1,026sqm typical floorplate', },
        { type: 'spacer', height: '50px', },
        { type: 'button', text: 'View 2D', state: { version: '2d' }, },
        { type: 'button', text: 'View 3D', state: { version: '3d' }, },
    ]
};

export const midrise = {
    type: 'floorplan',
    options: {color: '#8a1438'},
    className: 'split-page--square-right floorplan-image-nudge floorplan__smaller',
    img: {
        level2d: '/imgs/floorplans/floorplan_midrise_2d.png',
        level3d: '/imgs/floorplans/floorplan_midrise_3d.png',
    },
    pageContents: [
        { type: 'title', text: 'Typical Floors <br/> Mid-Rise', },
        { type: 'statement', text: 'Sight lines to external views', },
        { type: 'statement', text: 'Designed to allow natural light flow', },
        { type: 'statement', text: '1,262sqm typical floorplate', },
        { type: 'spacer', height: '50px', },
        { type: 'button', text: 'View 2D', state: { version: '2d' }, },
        { type: 'button', text: 'View 3D', state: { version: '3d' }, },
    ]
};

export const lowrise = {
    type: 'floorplan',
    options: {color: '#8a1438'},
    className: 'split-page--square-right floorplan-image-nudge floorplan__smaller',
    img: {
        level2d: '/imgs/floorplans/floorplan_lowrise_2d.png',
        level3d: '/imgs/floorplans/floorplan_lowrise_3d.png',
    },
    pageContents: [
        { type: 'title', text: 'Typical Floors <br/> Low-Rise', },
        { type: 'statement', text: 'SIDE CORE efficiency', },
        { type: 'statement', text: 'Opportunity for wintergarden in the northeast corner', },
        { type: 'statement', text: '1,385sqm typical floorplate', },
        { type: 'spacer', height: '50px', },
        { type: 'button', text: 'View 2D', state: { version: '2d' }, },
        { type: 'button', text: 'View 3D', state: { version: '3d' }, },
    ]
};