const data = {
    dexus: {
        pageContents: (
            <div className='team--copy-contents'>
                <div className='team--about'>
                    <h1 className='team--about-title'>Dexus</h1>
                    <p className='team--about-para'>Dexus is one of Australia’s leading real asset groups, managing a high-quality Australasian real estate and infrastructure portfolio valued at $62.3 billion*. We directly own $17.8 billion of office, industrial, retail, healthcare and infrastructure assets and investments.</p>
                    <p className='team--about-para'>We manage a further $44.5 billion* of investments in our funds management business. The group’s $15.8 billion development pipeline provides the opportunity to grow both portfolios and enhance future returns. We consider sustainability (ESG) to be an integral part of our business.</p>
                    <p className='team--about-para'>With over 35 years of expertise in property investment, funds management, asset management and development, we have a proven track record delivering superior returns for investors and quality products for our customers.</p>
                    <p className='team--about-italic'>*Pro forma post completion of the Collimate Capital acquisition</p>
                </div>
                <div className='team--divider'></div>
                <div className='team--stats'>
                    <span className='team--stats-emphisis'>$62.3B</span>
                    <span className='team--stats-subtitle'>portfolio</span>
                    <span className='team--stats-emphisis'>36 YEARS</span>
                    <span className='team--stats-subtitle'>experience</span>
                    <span className='team--stats-emphisis'>$44.5B</span>
                    <span className='team--stats-subtitle'>funds under management</span>
                    <span className='team--stats-emphisis'>$15.8B</span>
                    <span className='team--stats-subtitle'>development pipeline</span>
                </div>
            </div>
        ),
        image: '/imgs/team/dexus.jpg'
    },
    batesSmart: {
        pageContents: (
            <div className='team--copy-contents'>
                <div className='team--about'>
                    <h1 className='team--about-title'>Bates Smart</h1>
                    <p className='team--about-para'>City making designs that uplift.</p>
                    <p className='team--about-para'>Bates Smart is a renowned city-making design practice combining architecture, masterplanning and interior design. Their team of over 250 professionals in Melbourne and Sydney work in a highly collaborative manner to create places and spaces that improve people's lives.</p>
                    <p className='team--about-para'>Through more than 165 years of design, Bates Smart has made a significant contribution to the shape and fabric of Australian cities. Their work reflects an enduring timeline of Australian development, having designed historic landmarks and modern, contemporary buildings including ICI House, Australia's first skyscraper, Collins House, Australia's slimmest residential tower and The Royal Children's Hospital.</p>
                    <p className='team--about-para'>Bates Smart's combined expertise and integrated design approach allow to them lead complex projects across sectors and industries, positively impacting the ways in which people live, learn, work and heal.</p>
                </div>
                <div className='team--divider'></div>
                <div className='team--stats'>
                    <span className='team--stats-emphisis'>165+ YEARS</span>
                    <span className='team--stats-subtitle'>experience</span>
                    <span className='team--stats-emphisis'>250+</span>
                    <span className='team--stats-subtitle'>design professionals</span>
                    <span className='team--stats-emphisis'>Award Winning</span>
                    <span className='team--stats-subtitle'>designs</span>
                    <span className='team--stats-emphisis'>Diverse</span>
                    <span className='team--stats-subtitle'>office portfolio</span>
                </div>
            </div>
        ),
        image: '/imgs/team/bates-smart.jpg'
    }
}


export default data;
export const dexus = data.dexus;
export const batesSmart = data.batesSmart;