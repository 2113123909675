
import React from 'react';
import ConnectViewResidencesRotation from './ConnectViewResidencesRotation';
import ConnectViewResidencesUIRotate from './ConnectViewResidencesUIRotate';
import LoadContainer from '../../components/LoadContainer';
import './stylesResidences.scss';

export default function ViewResidences () {
    
    const rotations = [];
    for (let i = 0; i <= 72; i++) { 
        rotations.push(
            <ConnectViewResidencesRotation key={ i } rotation={ i } />
        );
    }

    return (
        <LoadContainer>
            <div className='residences--rotations'> 
                { rotations }
            </div>
            <ConnectViewResidencesUIRotate />
        </LoadContainer>
    );
}