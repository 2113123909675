
export const dataCGIGallery1 = [
    { title: '1', left: '68px', top: '17px', src: '/imgs/gallery/gallery_image_1_thumb.jpg', href: '/imgs/gallery/gallery_image_1_enlarge.jpg' },
	{ title: '2', left: '828px', top: '17px', src: '/imgs/gallery/gallery_image_2_thumb.jpg', href: '/imgs/gallery/gallery_image_2_enlarge.jpg' },
	{ title: '3', left: '1347px', top: '17px', src: '/imgs/gallery/gallery_image_3_thumb.jpg', href: '/imgs/gallery/gallery_image_3_enlarge.jpg' },
	{ title: '4', left: '828px', top: '338px', src: '/imgs/gallery/gallery_image_4_thumb.jpg', href: '/imgs/gallery/gallery_image_4_enlarge.jpg' },
	{ title: '5', left: '1347px', top: '284px', src: '/imgs/gallery/gallery_image_5_thumb.jpg', href: '/imgs/gallery/gallery_image_5_enlarge.jpg' },
	{ title: '6', left: '68px', top: '498px', src: '/imgs/gallery/gallery_image_6_thumb.jpg', href: '/imgs/gallery/gallery_image_6_enlarge.jpg' },
	{ title: '7', left: '449px', top: '498px', src: '/imgs/gallery/gallery_image_7_thumb.jpg', href: '/imgs/gallery/gallery_image_7_enlarge.jpg' },
	{ title: '8', left: '828px', top: '659px', src: '/imgs/gallery/gallery_image_8_thumb.jpg', href: '/imgs/gallery/gallery_image_8_enlarge.jpg' },
	{ title: '9', left: '1347px', top: '712px', src: '/imgs/gallery/gallery_image_9_thumb.jpg', href: '/imgs/gallery/gallery_image_9_enlarge.jpg' },
];

export const dataCGIGallery2 = [
    { title: '1', left: '68px', top: '17px', src: '/imgs/gallery/gallery_page2_image_1_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '518px', top: '17px', src: '/imgs/gallery/gallery_page2_image_2_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '967px', top: '17px', src: '/imgs/gallery/gallery_page2_image_3_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1416px', top: '17px', src: '/imgs/gallery/gallery_page2_image_4_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '68px', top: '338px', src: '/imgs/gallery/gallery_page2_image_5_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '518px', top: '338px', src: '/imgs/gallery/gallery_page2_image_6_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '967px', top: '338px', src: '/imgs/gallery/gallery_page2_image_7_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1416px', top: '338px', src: '/imgs/gallery/gallery_page2_image_8_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '68px', top: '659px', src: '/imgs/gallery/gallery_page2_image_9_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '518px', top: '659px', src: '/imgs/gallery/gallery_page2_image_10_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_10_enlarge.jpg' },
	// { title: '11', left: '967px', top: '659px', src: '/imgs/gallery/gallery_page2_image_11_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_11_enlarge.jpg' },
	// { title: '12', left: '1416px', top: '659px', src: '/imgs/gallery/gallery_page2_image_12_thumb.jpg', href: '/imgs/gallery/gallery_page2_image_12_enlarge.jpg' },
];

export const dataResidences = [
	{ title: 'v02', left: '298px', top: '100px', src: './imgs/gallery/QWT/v02-tmb.jpg', href: './imgs/gallery/QWT/v02.jpg' },
    { title: 'v12', left: '522px', top: '100px', src: './imgs/gallery/QWT/v12-tmb.jpg', href: './imgs/gallery/QWT/v12.jpg' },
	{ title: 'v13', left: '745px', top: '100px', src: './imgs/gallery/QWT/v13-tmb.jpg', href: './imgs/gallery/QWT/v13.jpg' },
	{ title: 'v09', left: '1193px', top: '100px', src: './imgs/gallery/QWT/v09-tmb.jpg', href: './imgs/gallery/QWT/v09.jpg' },
	{ title: 'v17', left: '522px', top: '411px', src: './imgs/gallery/QWT/v17-tmb.jpg', href: './imgs/gallery/QWT/v17.jpg' },
	{ title: 'v08', left: '745px', top: '411px', src: './imgs/gallery/QWT/v08-tmb.jpg', href: './imgs/gallery/QWT/v08.jpg' },
	{ title: 'v14', left: '969px', top: '411px', src: './imgs/gallery/QWT/v14-tmb.jpg', href: './imgs/gallery/QWT/v14.jpg' },
	{ title: 'v03', left: '1192px', top: '255px', src: './imgs/gallery/QWT/v03-tmb.jpg', href: './imgs/gallery/QWT/v03.jpg' },
	{ title: 'v19', left: '1416px', top: '255px', src: './imgs/gallery/QWT/v19-tmb.jpg', href: './imgs/gallery/QWT/v19.jpg' },
	{ title: 'v20', left: '1416px', top: '411px', src: './imgs/gallery/QWT/v20-tmb.jpg', href: './imgs/gallery/QWT/v20.jpg' },
	{ title: 'v21', left: '1416px', top: '567px', src: './imgs/gallery/QWT/v21-tmb.jpg', href: './imgs/gallery/QWT/v21.jpg' },
	{ title: 'v22', left: '298px', top: '567px', src: './imgs/gallery/QWT/v22-tmb.jpg', href: './imgs/gallery/QWT/v22.jpg' },
	{ title: 'v01', left: '745px', top: '567px', src: './imgs/gallery/QWT/v01-tmb.jpg', href: './imgs/gallery/QWT/v01.jpg' },
	{ title: 'v15', left: '969px', top: '567px', src: './imgs/gallery/QWT/v15-tmb.jpg', href: './imgs/gallery/QWT/v15.jpg' },
	{ title: 'v16', left: '1193px', top: '723px', src: './imgs/gallery/QWT/v16-tmb.jpg', href: './imgs/gallery/QWT/v16.jpg' },
	{ title: 'v10', left: '298px', top: '879px', src: './imgs/gallery/QWT/v10-tmb.jpg', href: './imgs/gallery/QWT/v10.jpg' },
	{ title: 'v18', left: '969px', top: '879px', src: './imgs/gallery/QWT/v18-tmb.jpg', href: './imgs/gallery/QWT/v18.jpg' },
	{ title: 'v11', left: '1192px', top: '879px', src: './imgs/gallery/QWT/v11-tmb.jpg', href: './imgs/gallery/QWT/v11.jpg' },
	{ title: 'v04', left: '1416px', top: '879px', src: './imgs/gallery/QWT/v04-tmb.jpg', href: './imgs/gallery/QWT/v04.jpg' },

];

export const dataInteriors = [
    { title: 'v34', left: '298px', top: '99px', src: './imgs/gallery/interiors/v34-tmb.jpg', href: './imgs/gallery/interiors/v34.jpg' },
    { title: 'v35', left: '522px', top: '99px', src: './imgs/gallery/interiors/v35-tmb.jpg', href: './imgs/gallery/interiors/v35.jpg' },
    { title: 'v36', left: '745px', top: '99px', src: './imgs/gallery/interiors/v36-tmb.jpg', href: './imgs/gallery/interiors/v36.jpg' },
    { title: 'v37', left: '969px', top: '99px', src: './imgs/gallery/interiors/v37-tmb.jpg', href: './imgs/gallery/interiors/v37.jpg' },
    { title: 'v38', left: '1192px', top: '99px', src: './imgs/gallery/interiors/v38-tmb.jpg', href: './imgs/gallery/interiors/v38.jpg' },
    { title: 'v32', left: '1416px', top: '99px', src: './imgs/gallery/interiors/v32-tmb.jpg', href: './imgs/gallery/interiors/v32.jpg' },

    { title: 'v28', left: '298px', top: '254px', src: './imgs/gallery/interiors/v28-tmb.jpg', href: [ './imgs/gallery/interiors/v28a.jpg', './imgs/gallery/interiors/v28b.jpg' ] },
    { title: 'v29', left: '745px', top: '254px', src: './imgs/gallery/interiors/v29-tmb.jpg', href: [ './imgs/gallery/interiors/v29a.jpg', './imgs/gallery/interiors/v29b.jpg' ] },
    { title: 'v31', left: '1192px', top: '254px', src: './imgs/gallery/interiors/v31-tmb.jpg', href: [ './imgs/gallery/interiors/v31a.jpg', './imgs/gallery/interiors/v31b.jpg' ] },

    { title: 'v33', left: '1192px', top: '564px', src: './imgs/gallery/interiors/v33-tmb.jpg', href: './imgs/gallery/interiors/v33.jpg' },
    { title: 'v27', left: '298px', top: '564px', src: './imgs/gallery/interiors/v27-tmb.jpg', href: './imgs/gallery/interiors/v27.jpg' },
    { title: 'v30', left: '745px', top: '564px', src: './imgs/gallery/interiors/v30-tmb.jpg', href: './imgs/gallery/interiors/v30.jpg' },

    { title: 'v23', left: '298px', top: '722px', src: './imgs/gallery/interiors/v23-tmb.jpg', href: [ './imgs/gallery/interiors/v23a.jpg', './imgs/gallery/interiors/v23b.jpg' ] },
    { title: 'v24', left: '298px', top: '879px', src: './imgs/gallery/interiors/v24-tmb.jpg', href: [ './imgs/gallery/interiors/v24a.jpg', './imgs/gallery/interiors/v24b.jpg' ] },
    { title: 'v25', left: '745px', top: '722px', src: './imgs/gallery/interiors/v25-tmb.jpg', href: [ './imgs/gallery/interiors/v25a.jpg', './imgs/gallery/interiors/v25b.jpg' ] },
    { title: 'v26', left: '969px', top: '722px', src: './imgs/gallery/interiors/v26-tmb.jpg', href: [ './imgs/gallery/interiors/v26a.jpg', './imgs/gallery/interiors/v26b.jpg' ] },
    { title: 'v39', left: '1192px', top: '722px', src: './imgs/gallery/interiors/v39-tmb.jpg', href: [ './imgs/gallery/interiors/v39a.jpg', './imgs/gallery/interiors/v39b.jpg' ] },
];