import React from 'react';
import ViewSlider from '../../components/slider/ViewSlider';


export default function ViewHome () {
    const slideList = ([
        '/imgs/home/home-1.jpg',
        '/imgs/home/home-2.jpg',
        '/imgs/home/home-3.jpg',
        '/imgs/home/home-4.jpg',
    ]).map((img) => ({ src: img, options: {} }) );

    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
		speed: 500,
		infinite: true,
	};

    return (
        <ViewSlider data={ { slideList: slideList, sliderOptions: settings } } />
    );

}