import React from 'react';
import { connect } from 'react-redux';
import LoadContainer from '../../components/LoadContainer';
import { selectOverlay } from '../../components/overlay';
import './stylesVision.scss';


function ViewVirtualTour ( { selectOverlay } ) {
    return (
        <LoadContainer>
            <img src={ process.env.PUBLIC_URL + '/imgs/vision/vision_bkg_2.jpg' } width='1920' height='1080' alt='' />
            <button className='vision--button__left-half'
                onClick={ () => selectOverlay( { type: 'vr', src: '/tours/VR_TOUR_LOBBY/index.htm', options: { fullScreen: true } } ) }></button>
            <button className='vision--button__right-half'
                onClick={ () => selectOverlay( { type: 'vr', src: '/tours/VR_TOUR_HIGH-RISE/index.htm', options: { fullScreen: true } } ) }></button>
        </LoadContainer>
    );
}

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
    selectOverlay: (data) => dispatch(selectOverlay(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewVirtualTour);