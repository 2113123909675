import React from 'react';
import { connect } from 'react-redux';
import './stylesHotspots.scss'


function ButtonLightingHotspot ( { data, isOn, onClickOn, onClickOff } ) {

    const onClick = () => {
        if (isOn)
        {
            onClickOff();
        }
        else
        {
            onClickOn();
        }
    }

    return (
        <div className='hotspot' style={ { left: data.left, top: data.top } }>
            <button className={ `hotspot--lighting-btn${ isOn ? '__active' : '' }` } onClick={ onClick }> { data.title } </button>
        </div>
    );

}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonLightingHotspot);