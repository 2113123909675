import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { gsap } from 'gsap/all';
import { deselectLevel, selectLevel } from '.';


function ViewResidencesLevelGroup ( { data, residences, selectLevel, deselectLevel } ) {

    const subMenuRef = useRef(null);
    const title = data.title;

    const getLevels = () => {
        const btnList = [];
        if (data.levelGroups !== undefined) {
            const dataList = data.levelGroups; 
            for (let i = 0; i < dataList.length; i++) {
                const level = String(dataList[i]);
                const levelSelected = residences.levels.indexOf(level) > -1 && residences.areLevelsActive;
                btnList.push(
                    <li key={i}>
                        <button 
                            className={ 'residences--levels-sub-menu-item' + (levelSelected ? '__active' : '') }
                            onClick={ () => levelSelected ? deselectLevel(level) : selectLevel(level) } >
                                LEVEL { level }
                        </button>
                    </li>
                );
            }
        } 
        return btnList;
    }

    useEffect(() => {
        if ( residences.levelGroup === title && residences.isLevelGroupActive && residences.area === title && residences.isAreaActive ) {
            subMenuRef.current.style.display = 'block';
            gsap.to(subMenuRef.current, 0.3, { height: 'auto', opacity: 1, overwrite: true } );
        }
        else {
            gsap.to(subMenuRef.current, 0.3, { height: '0', opacity: 0, overwrite: true } );
        }
    }, [residences, title]);

    

    return (
        <div className='residences--levels-sub-menu' ref={subMenuRef} style={ { left: data.left, top: data.top } }>
            <ul>
                { getLevels() }
            </ul>
        </div> 
    );

}


const mapStateToProps = (state) => ({
    residences: state.residences
})

const mapDispatchToProps = (dispatch) => ({
    selectLevel: (level) => dispatch(selectLevel(level)),
    deselectLevel: (level) => dispatch(deselectLevel(level))
})


export default connect(mapStateToProps, mapDispatchToProps)(ViewResidencesLevelGroup)