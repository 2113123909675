import React from 'react';
import { connect } from 'react-redux';


function ViewItem ( { item, filePrefix, fileSufix, currItem } ) {
  
  //replace spaces with - and remove grammar
  const itemStripped = item.replace(/\s/g, '-').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase();
  const iconSrc = filePrefix + itemStripped + fileSufix;

  //only if it's the currently active item
  const display = item === currItem ? 'block' : 'none';

  return (
      <img className='shared--img-fill' style={ { display: display } } src={ process.env.PUBLIC_URL + iconSrc } width='1920' height='1080' alt='item' />
  );
  
}


const mapStateToProps = (state) => ({
  currItem: state.multipage.currItem
})

export default connect(mapStateToProps)(ViewItem);