import React, {useState} from 'react';
import { connect } from 'react-redux';
import { selectOverlay } from '../../components/overlay';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import { lobby as lobbyData } from '../../data/floorplans'; 
import './stylesHighlights.scss';

function ConnectViewArrival ( { selectOverlay } ) {

    const [page, setPage] = useState(0);
    
    const images = [
        '/imgs/building-highlights/arrival-collins.jpg',
        '/imgs/building-highlights/arrival-exhibition.jpg',
        '/imgs/building-highlights/arrival-mcgraths.jpg',
    ];

    const generateImage = (src) => <img className='image highlights--image' src={ process.env.PUBLIC_URL + src } alt='' />;

    const showLobbyOverlay = () => {
        selectOverlay( lobbyData );
    }

    const generatePage = ( statements ) => { 
        return (
            <div className='highlights--copy-container'>
                <h1 className='title highlights--title'>Arrival Experience</h1>
                <div className={ 'highlights--button-container' }>
                    <button className={ `button${ page === 0 ? '__active' : '' } highlights--button${ page === 0 ? '__active' : '' }` } 
                        onClick={ () => setPage(0) } >Collins Street</button>
                    <button className={ `button${ page === 1 ? '__active' : '' } highlights--button${ page === 1 ? '__active' : '' }` } 
                        onClick={ () => setPage(1) } >Exhibition Street</button>
                    <button className={ `button${ page === 2 ? '__active' : '' } highlights--button${ page === 2 ? '__active' : '' }` } 
                        onClick={ () => setPage(2) } >McGraths Lane</button>
                </div>
                <div className={ 'statments-container highlights--statments-container' }>
                    { statements }
                </div>
                <button className={ 'button__active highlights--button__active' } onClick={ showLobbyOverlay }>
                    View Lobby Plan
                </button>
            </div>
        );
    }
    const pages = [
        (<>
            <p className='statement highlights--statement'>A grand and formal entrance</p>
            <p className='statement highlights--statement'>Warm and inviting interiors</p>
            <p className='statement highlights--statement'>Impressive client arrival experience</p>
            <p className='statement highlights--statement'>Access to 52 Collins retail</p>
        </>),
        (<>
            <p className='statement highlights--statement'>Direct access to the upper floors</p>
            <p className='statement highlights--statement'>Immediate access to the 80 Collins precinct</p>
            <p className='statement highlights--statement'>Wellness and end-of-trip connectivity</p>
            <p className='statement highlights--statement'>Functional access to public transport</p>
        </>),
        (<>
            <p className='statement highlights--statement'>Link between Collins and Little Collins</p>
            <p className='statement highlights--statement'>Authentic Melbourne experience</p>
            <p className='statement highlights--statement'>Bluestone laneway similar to DeGraves Street</p>
            <p className='statement highlights--statement'>Direct access to lobby caf&eacute;</p>
        </>),
    ];

    return (
        <ViewSplitPage 
            className='highlights--transform-container highlights--split-page highlights--arrival'
            pageLeft={ generatePage(pages[page]) }
            pageRight={ generateImage(images[page]) } />
    );
}

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
    selectOverlay: (data) => dispatch(selectOverlay(data))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ConnectViewArrival)