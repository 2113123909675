import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import lighting from './lighting';


function LightingHandler ( { residences } ){

    // Set the lighting selection to the selected apartment plan
    useEffect(() => {
        const lightingCall = async () => {
            if (residences.isAllActive)
            {
                lighting.allOn();
            }
            else
            {
                await lighting.allOff();

                if ( residences.areLevelsActive && residences.levels )
                {
                    for (let idx=0; idx<residences.levels.length; idx++)
                    {
                        lighting.turnOnLevel(residences.levels[idx]);
                    }
                }
                else if ( residences.isAreaActive && residences.area )
                {
                    lighting.turnOnArea(residences.area);
                }
            }
        }
        lightingCall();
        
    }, [ residences.isAllActive, residences.area, residences.isAreaActive, residences.levels, residences.areLevelsActive ]);

    return <></>;
}

const mapStateToProps = (state) => ({
    residences: state.residences
})

const mapDispatchToProps = (dispatch) => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(LightingHandler)