
import { gsap } from 'gsap/all';

export const utilAnimate = {
    fadeIn: (element, speed=0.3) => {
        gsap.killTweensOf(element);
        let tl = gsap.timeline();
        tl.set(element, {
            onComplete: () => {
                element.style.display = 'block';
            }
        })
        .to(element, {
            duration: speed,
            ease: 'ease-in-out',
            opacity: 1,
        });
    },
    fadeOut: (element, speed=0.3) => {
        gsap.killTweensOf(element);
        let tl = gsap.timeline();
        tl.to(element, {
            duration: speed,
            opacity: 0,
        }).set(element, {
            onComplete: () => {
                element.style.display = 'none';
            }
        });
    }
}
