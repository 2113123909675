import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesHighlights.scss';

export default function ViewPrecinct () {
    
    const image = <img className='image highlights--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/precinct.jpg' } alt='' />;

    const page = (
        <div className='copy-container highlights--copy-container'>
            <h1 className='title highlights--title'>The Collins Street Precinct</h1>
            <p className='statement highlights--statement'>Perfect mix of cafe culture, fine dining and cocktail bars</p>
            <p className='statement highlights--statement'>High-end retail and boutique hotel</p>
            <p className='statement highlights--statement'>Fitzroy, Treasury Gardens and Parliament House to the east</p>
            <p className='statement highlights--statement'>Recreation and entertainment venues within easy reach</p>
        </div>
    );
    
    return (
        <ViewSplitPage
            className='highlights--transform-container highlights--split-page'
            pageLeft={ page }
            pageRight={ image } />
    );
}