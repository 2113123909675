import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import configureAppStore from './app/configureAppStore';
import utilsInit from './util';

utilsInit();

const container = document.getElementById('root');
const root = createRoot(container);
const store = configureAppStore();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
